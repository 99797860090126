import Ajax from "Utils/ajax";
export const leaveType = () => Ajax.post('hr/leaveType'); 
export const getAllStaffByHRorManager = (data) => Ajax.post('hr/getAllStaffByHRorManager',data);
export const leaveReason = () => Ajax.post('hr/leaveReason'); 
export const leaveMatrix = (data) => Ajax.post('hr/leaveMatrix',data); 
export const leaveBalance = (data) => Ajax.post('hr/leaveBalance',data);
export const applyStaffLeave = (data) => Ajax.post('hr/applyStaffLeave',data);
export const validateLeave = (data) => Ajax.post('hr/validateLeave',data);
export const leaveList = (data) => Ajax.post('hr/leaveList',data);
export const removeLeave = (data) => Ajax.post('hr/removeLeave',data);
export const getLeavePlanner = (data) => Ajax.post('hr/getLeavePlanner',data);